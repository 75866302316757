.container {
  padding-top: 10rem;
  padding-bottom: 4rem;
}

.container2 {
  padding-top: 13rem;
  padding-bottom: 4rem;
}

/* Fixed layout for smaller devices */
@media only screen and (max-width: 991px) {
  .container {
    padding-top: 2rem !important;
  }

  .container2 {
    padding-top: 2rem !important;
  }
}

.body {
  margin-top: 2rem;
}
