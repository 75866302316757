.main {
  display: flex;
  justify-content: space-between;

  :global(.ui.selection.dropdown) {
    min-width: 12rem;
  }

  :global(.ui.input) {
    max-width: 12rem;
  }

  :global(.ui.button) {
    white-space: nowrap;
  }

  .left {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;

    .dropdown {
      margin-right: 1rem;
      display: flex;
      align-items: center;

      & > label {
        margin-right: 0.25rem;
      }
    }

    .toggle {
      margin-top: 0.5rem;
      display: flex;

      & > label {
        margin-left: 0.5rem;
      }
    }
  }

  .right {
    flex: 1 1 0px;
    display: flex;
    justify-content: flex-end;
    margin-left: 1rem;

    .dropdown {
      margin-right: 1rem;
    }

    .input {
      margin-right: 1rem;
    }

    .button {
      margin-right: 0.25rem;

      .button-text {
        margin-left: 0.5rem;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
