.react-datepicker * {
  outline: none;
}

.react-datepicker {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  border: 1px solid rgba(34, 36, 38, 0.15);
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  cursor: default;
}

.react-datepicker__header {
  background-color: #f9fafb;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  border-bottom-color: rgb(222 222 223);
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  border-bottom-color: rgb(249 250 251);
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-weight: 700;
  font-size: 1em;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0;
  border-color: rgba(0, 0, 0, 0.87);
}

.react-datepicker__navigation {
  top: 8px;
}

.react-datepicker__navigation:hover *::before {
  border-color: rgba(0, 0, 0);
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 87px;
}

.react-datepicker__navigation-icon--next {
  left: 0px;
}

.react-datepicker__navigation-icon {
  top: 3px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin: 8px 0;
}

.react-datepicker__month {
  margin: 0;
  background: #e8e9e9;
}

.react-datepicker__day-names {
  margin-top: 4px;
}

.react-datepicker__day-name {
  font-weight: 700;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 40px;
  line-height: 36px;
  margin: 0;
}

.react-datepicker__day {
  border: none;
  background: white;
  margin: 0.5px;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  font-weight: 700;
  color: white;
  background: #f2711c;
  border-radius: 0;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background: #f26202;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background: white;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  pointer-events: none;
  color: inherit;
  opacity: 0.5;
}

.react-datepicker__day--selected.react-datepicker__day--disabled {
  color: white;
}

.react-datepicker__time-container {
  border-color: rgba(34, 36, 38, 0.1);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  padding: 8px 10px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: #f2711c;
  color: white;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: auto;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background: #f26202;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0;
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: #f0f0f0;
}

.react-datepicker__day--outside-month {
  pointer-events: none;
  cursor: default;
  color: rgba(40, 40, 40, 0.3);
}
.react-datepicker__day--outside-month:hover {
  background: white;
}
