.main {
  min-height: 100vh;
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .codes {
    width: 578px;
    margin: 40px auto;
  }

  .code {
    display: flex;
    gap: 1.5rem;

    .input {
      flex: 1 1 0px;

      input {
        text-align: center !important;
        font-size: large !important;
        background: #eaeaea !important;
      }
    }
  }
}
